module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-Z6G7R9HERM"],"gtagConfig":{"optimize_id":"OPT_CONTAINER_ID","anonymize_ip":true,"cookie_expires":0},"pluginConfig":{"head":true,"respectDNT":false,"exclude":[],"origin":"https://www.googletagmanager.com","delayOnRouteUpdate":0}},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"languages":["en","fr","zh-TW"],"defaultLanguage":"en","siteUrl":"https://www.jordybouchard.com","i18nextOptions":{"fallbackLng":"en","interpolation":{"escapeValue":false}},"localeJsonSourceName":"locales","pages":[{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/appconjugate","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/contact","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/hybrx","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/infociti","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/lsf","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/mikimeal","getLanguageFromPath":true,"excludeLanguages":[]},{"matchPath":"/:lang?/restomouv","getLanguageFromPath":true,"excludeLanguages":[]}]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
